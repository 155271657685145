import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./bot.css";

import chatbotImage from "../assets/images/Chatbot.png";
import chatbotLeftImage from "../assets/images/chatbot-leftimg.png";
import chatbotLogo from "../assets/images/chatbot-img.png";
import agentIcon from "../assets/images/Agent.png";
import userIcon from "../assets/images/User.jfif";
import "bootstrap/dist/css/bootstrap.min.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useWebSocket, { ReadyState } from "react-use-websocket";
import FormModal from "./FormModal";
import deleteIcon from "../assets/images/Delete-24.png";
import { v4 as uuidv4 } from "uuid";
import { Spinner } from "react-bootstrap";
import moment from "moment";
import NotificationModal from "./NotificationModal";
import {
  createNewLeadData,
  customerAndSalesRepresentativeUnavailableAPI,
  fetchExistLeadData,
  fetchMessage,
  fetchSalesRepresentative,
  sendChatMessage,
} from "../services/APIs";
const Chat = () => {
  const [flag, setFlag] = useState(0);
  const scrollRef = useRef(null);
  const [error, setError] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [agentName, setAgentName] = useState("");
  const [initialMessage, setInitialMessage] = useState("");
  const [confirmAddress, setConfirmAddress] = useState("");
  const [confirmFullName, setConfirmFullName] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmPhone, setConfirmPhone] = useState("");
  const [displayInputValue, setDisplayInputValue] = useState("");
  const [inputBillValue, setInputBillValue] = useState("");
  const [questionNumber, setQuestionNumber] = useState("");
  const [checkUUID, setCheckUUID] = useState("");
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [inputNameValue, setInputNameValue] = useState("");
  const [inputEmailValue, setInputEmailValue] = useState("");
  const [inputPhoneValue, setInputPhoneValue] = useState("");
  const [customerRequest, setCustomerRequest] = useState("");
  const [staticQuestion, setStaticQuestion] = useState("");
  const [messages, setMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState([""]);
  const [messageInput, setMessageInput] = useState([]);
  const [responses, setResponses] = useState([]); // State to track multiple responses
  const [responsesNew, setResponsesNew] = useState([]);
  const [senderID, setSenderID] = useState(null);
  const [messageHistory, setMessageHistory] = useState([]);
  const [notiFicationObject, setNotiFicationObject] = useState({});
  const [homeAddressCorrect, setHomeAddressCorrect] = useState("yes");
  const [customerTime, setCustomerTime] = useState(null);
  const [isVisible, setIsVisible] = useState(false); // State to track visibility
  const [showModal, setShowModal] = useState(false);
  const [showInput, setShowInput] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isOtherQuesLoading, setIsOtherQuesLoading] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [isInputVisible, setInputVisible] = useState(false);
  const [notificationConfirmation, setNotificationConfirmation] =
    useState(false);
  const [timer, setTimer] = useState(null);
  const [btnDisable, setBtnDisable] = useState({
    yes: false,
    no: false,
  });
  const [noNewMessages, setNoNewMessages] = useState(true);

  const { uid } = useParams();
  const uuid = uid;
  let myuuid = uuidv4();
  const socketUrl = `wss://uat-api.veragoodrep.com/ws/live-chat/${uuid}/`;

  const payload = {
    id: myuuid,
    senderId: senderID,
    customer_id: senderID,
    message: displayInputValue,
    timestamp: new Date().toISOString(),
  };

  // const { sendMessage, lastMessage, readyState } = useWebSocket(
  //   socketUrl,
  //   payload
  // );

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onError: (event) => {
      console.error("WebSocket error observed:", event);
      // Handle the error (e.g., show a notification to the user)
      toast.error("WebSocket connection error. Please try again.");
    },
    onOpen: () => {
      console.log("WebSocket connection established");
    },
    onClose: () => {
      console.log("WebSocket connection closed");
    },
    shouldReconnect: (closeEvent) => true, // Automatically reconnect on close
  });

  /* write code for auto scroll end*/

  // add this logic 3 minutes countdown

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchSalesRepresentative(uuid);
        setIsLoading(true); // Set loading to false after the response is received
        if (res?.data?.exists === true) {
          setIsLoading(false);
          setCheckUUID(true);
        } else {
          setIsLoading(false);
          setCheckUUID(false);
        }
      } catch (error) {
        setIsLoading(false); // Ensure loading is false on error
        toast.error("This sales representative Id doesn't exists:");
      }
    };

    fetchData();
  }, [uuid]); // Use uuid as a dependency

  useEffect(() => {
    if (lastMessage !== null) {
      // Clear previous timer on new message
      if (timer) {
        clearTimeout(timer);
      }

      setNoNewMessages(false);

      let chatData = JSON.parse(lastMessage.data);
      setMessageHistory((prev) => prev.concat(lastMessage));

      // Process the incoming message
      if (chatData?.senderId === senderID && notificationConfirmation) {
        setCustomerTime(chatData?.timestamp);
        setChatMessage((prevChatMessages) => [
          ...prevChatMessages,
          {
            user1: chatData?.message,
            user2: null,
            time1: chatData?.timestamp,
            time2: null,
          },
        ]);
      } else if (
        chatData?.senderId === uuid &&
        chatData?.customer_id === senderID &&
        notificationConfirmation
      ) {
        setChatMessage((prevChatMessages) => [
          ...prevChatMessages,
          {
            user1: null,
            time1: null,
            user2: chatData?.message,
            time2: chatData?.timestamp,
          },
        ]);
      }

      // Set a new timer for 3 minutes (180000 milliseconds)
      const newTimer = setTimeout(async () => {
        // setIsLoading(true);
        await customerAndSalesRepresentativeUnavailableAPI(senderID, uuid);
        // setIsLoading(false);
        setShowInput(false); // Hide the input form
      }, 180000);
      setTimer(newTimer);
    }

    return () => {
      // Cleanup the timer on component unmount or when lastMessage changes
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [lastMessage, sendMessage]);

  useEffect(() => {
    if (!notificationConfirmation) return;
    let timeoutId;
    if (noNewMessages) {
      timeoutId = setTimeout(async () => {
        setNoNewMessages(false);
        await customerAndSalesRepresentativeUnavailableAPI(senderID, uuid);
        // window.location.reload(); // Reload the window
      }, 180000); // 30 seconds
    }

    return () => clearTimeout(timeoutId);
  }, [notificationConfirmation, noNewMessages]);

  const handleClickSendMessage = (e) => {
    // sendMessage(JSON.stringify(payload))
    e.preventDefault();
    sendMessage(JSON.stringify(payload));
    //setChatMessage( [...chatMessage, displayInputValue]);
    setDisplayInputValue([]);
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];
  console.log(connectionStatus);
  /* End Live Chat Integration Code */

  /* write code for auto scroll start*/
  function autoScroll() {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }

  useEffect(() => {
    // Call autoScroll whenever the component mounts or updates
    autoScroll();
  }, [responses]); // Empty dependency array means this runs once on mount

  const handleClick = async (message) => {
    setStaticQuestion(message);
    const formData = new FormData();
    formData.append("message1", message);
    setInitialMessage(message); // Store the initial message
    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setResponses([data.response]);
      setIsVisible(true);
      setInputVisible(false);
      setShowInput(false);
      setAgentName(null);
      setMessages([]);
      setChatMessage([]);
      setNotificationConfirmation(false);
    } catch (error) {
      toast.error("Something Went Wrong");
    }
  };

  const handleYesClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setResponses((prevResponses) => [...prevResponses, data.response]);
      setBtnDisable((prevState) => ({
        yes: true,
        no: false,
      }));
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  useEffect(() => {
    const updateDateTime = () => {
      const now = new Date();
      // Format the date and time
      const formattedDateTime = now.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      setCurrentDateTime(formattedDateTime);
    };

    updateDateTime();
    const intervalId = setInterval(updateDateTime, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setConfirmAddress(inputValue);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handleYesHometownClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handleNoHometownClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "no");

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setHomeAddressCorrect("no");
      setResponsesNew((prevResponses1) => [...prevResponses1, data.response]);
      //setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handleSpouseYesClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handleSpouseNoClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "no");

    try {
      // setIsLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/chat/${uuid}/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // setIsLoading(false);
      setHomeAddressCorrect("no");
      let msg =
        "Thanks for visit / interest and offers to email them the conversation they had today";
      setResponsesNew((prevResponses1) => [...prevResponses1, msg]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handleNameInputChange = (event) => {
    setInputNameValue(event.target.value);
  };

  const handleFormSubmit2 = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setConfirmFullName(inputNameValue);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Please enter first name and last name");
    }
  };

  const handleEmailInputChange = (event) => {
    setInputEmailValue(event.target.value);
  };

  const handleFormSubmit3 = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);

      setConfirmEmail(inputEmailValue);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handlePhoneInputChange = (event) => {
    setInputPhoneValue(event.target.value);
  };

  const handleFormSubmit4 = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);

      setConfirmPhone(inputPhoneValue);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handleYesBillClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {}
  };

  const handleBillInputChange = (event) => {
    setInputBillValue(event.target.value);
  };

  const handleFormSubmit5 = async (event) => {
    event.preventDefault(); // Prevent default form submission
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {}
  };

  const handleLastYesClick = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage);
    formData.append("message2", "yes");
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);
    formData.append("message10", "yes");

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      createnewLeadDyanamic();
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handleNoClickForBill = async () => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);
    formData.append("message10", "no");

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const parposalQuestion = async (questionNumber) => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);
    formData.append("message10", "no");
    formData.append("message11", questionNumber);

    try {
      //   setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      if (flag < 1) {
        setResponses((prevResponses) => [...prevResponses, data.response]);
        setFlag(1);
      } else {
        responses.pop();
        setResponses((prevResponses) => [...prevResponses, data.response]);
      }

      setQuestionNumber(questionNumber);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const lastQuestionConfirmation = async (customerRequest) => {
    const formData = new FormData();
    formData.append("message1", initialMessage); // Use the stored initial message
    formData.append("message2", "yes"); // Add the new message
    formData.append("message3", inputValue);
    formData.append("message4", "yes");
    formData.append("message5", inputNameValue);
    formData.append("message6", inputEmailValue);
    formData.append("message7", inputPhoneValue);
    formData.append("message8", "yes");
    formData.append("message9", inputBillValue);
    formData.append("message10", "no");
    formData.append("message11", questionNumber);
    formData.append("message12", customerRequest);

    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setCustomerRequest(customerRequest);
      toast.success(data.response);
      setResponses((prevResponses) => [...prevResponses, data.response]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handleClick1 = async (message) => {
    setStaticQuestion(message);
    const formData = new FormData();
    formData.append("message1", message);
    setInitialMessage(message); // Store the initial message
    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setResponses([data.response]);
      setIsVisible(true);
      setShowInput(false);
      setAgentName(null);
      setInputVisible(false);
      setNotificationConfirmation(false);
      setChatMessage([]);
      setMessages([]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const handleClick2 = async (message) => {
    setStaticQuestion(message);
    const formData = new FormData();
    formData.append("message1", message);
    setInitialMessage(message); // Store the initial message
    try {
      // setIsLoading(true);
      const data = await sendChatMessage(uuid, formData);
      // setIsLoading(false);
      setResponses([data.response]);
      setIsVisible(true);
      setShowInput(false);
      setAgentName(null);
      setInputVisible(false);
      setNotificationConfirmation(false);
      setChatMessage([]);
      setMessages([]);
    } catch (error) {
      toast.error("Error fetching data:");
    }
  };

  const toggleInputVisibility = () => {
    setResponses([]);
    setResponsesNew([]);
    setInputVisible(!isInputVisible);
    setShowInput(false);
    setNotificationConfirmation(false);
    setAgentName(null);
    setChatMessage([]);
    setInputValue("");
  };

  const handleKeyDown = async (e) => {
    const formData = new FormData();
    formData.append("message", inputValue);
    if (e.key === "Enter" || e.type === "click") {
      e.preventDefault(); // Prevent the default form submission
      if (inputValue.trim()) {
        try {
          setIsOtherQuesLoading(true);
          const response = await fetchMessage(formData);
          setIsOtherQuesLoading(false);

          setMessages([
            ...messages,
            { user: inputValue, bot: response?.data?.response },
          ]);
          setInputValue(""); // Clear input after sending
          setChatMessage([]);
        } catch (error) {
          toast.error(
            "Oops! Something went wrong while trying to fetch the chat messages. Please try again"
          );
        }
      }
    }
  };

  const handleShow = () => setShowModal(true);
  const handleClose = () => {
    setInputVisible(false);
    setShowModal(false);
    /* Clear the input fields */
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setAddress("");
    setShowInput(false);
    setNotificationConfirmation(false);
    setAgentName(null);
  };

  const handleLeadNotification = async (leadData, chat_coversation_check) => {
    const requestBody = {
      sales_representative_id: leadData.SaleRepresentative_id,
      customer_id: leadData.customer_id,
      home_owner_first_name: leadData.home_owner_first_name,
      home_owner_last_name: leadData.home_owner_last_name,
      chat_coversation: chat_coversation_check,
    };

    try {
      // setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/send-notification/`,
        requestBody
      );
      // setIsLoading(false);
      setNotificationConfirmation(true);
    } catch (error) {
      toast.error(
        "Sales representative is currently inactive please try again later!"
      ); // Show error toast
    }
  };

  const closeSocket = async (event) => {
    let result = await handleLeadNotification(notiFicationObject, "end");
    window.location.reload();
  };

  const createnewLeadDyanamic = async (event) => {
    const requestBodyForLeadCheck = {
      email: confirmEmail,
      sales_representative_id: uuid,
    };

    try {
      // setIsLoading(true);
      const response = await fetchExistLeadData(requestBodyForLeadCheck);
      // setIsLoading(false);
      if (!response?.data?.exists) {
        let nameSplit = confirmFullName.split(" ");

        if (nameSplit.length < 1) {
          nameSplit[0] = "Test";
          nameSplit[1] = "User";
        }

        const requestBody = {
          home_owner_first_name: nameSplit[0],
          home_owner_last_name: nameSplit[1],
          phone_no: confirmPhone,
          email_ID: confirmEmail,
          home_address: confirmAddress,
          SaleRepresentative_id: uuid,
        };

        try {
          // setIsLoading(true);
          const response = await createNewLeadData(requestBody);
          // setIsLoading(false);

          setSenderID(response.data.customer_id); // Log the success message
          setNotiFicationObject(response.data);
          toast.success("Connecting to an agent.");
          setShowInput(true);
          await handleLeadNotification(response.data, "start");
          setAgentName(response.data.SalesRepresentative_full_name);
          setResponses([]);
          setResponsesNew([]);
        } catch (error) {
          toast.error("Lead creation failed. Please try again!"); // Show error toast
          // Handle error appropriately
        }
      } else {
        setSenderID(response.data.customer_id); // Log the success message
        setNotiFicationObject(response.data);
        toast.success("Connecting to an agent.");
        setShowInput(true);
        // setIsLoading(true);
        await handleLeadNotification(response.data, "start");
        // setIsLoading(false);
        setAgentName(response.data.SalesRepresentative_full_name);
        setResponses([]);
        setResponsesNew([]);
      }
    } catch (error) {
      toast.error("Lead creation failed. Please try again!");
    }
  };

  function formatTimestampToAMPM(timestamp) {
    const formattedTime = moment(timestamp).format("h:mm a");

    return formattedTime;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    const requestBodyForLeadCheck = {
      email: email,
      sales_representative_id: uuid,
    };

    try {
      // setIsLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/lead-exists/`,
        requestBodyForLeadCheck
      );
      // setIsLoading(false);

      if (!response?.data?.exists) {
        const requestBody = {
          home_owner_first_name: firstName,
          home_owner_last_name: lastName,
          phone_no: phone,
          email_ID: email,
          home_address: address,
          SaleRepresentative_id: uuid,
        };

        try {
          // setIsLoading(true);
          const response = await createNewLeadData(requestBody);
          // setIsLoading(false);

          setSenderID(response.data.customer_id); // Log the success message
          setNotiFicationObject(response.data);
          handleClose();
          toast.success("Connecting to an agent.");
          setShowInput(true);
          await handleLeadNotification(response.data, "start");
          setAgentName(response.data.SalesRepresentative_full_name);
          setResponses([]);
          setMessages([]);
          setResponsesNew([]);
        } catch (error) {
          toast.error("Lead creation failed. Please try again!"); // Show error toast
          // Handle error appropriately
          handleClose();
        }
      } else {
        setSenderID(response.data.customer_id); // Log the success message
        setNotiFicationObject(response.data);
        handleClose();
        toast.success("Connecting to an agent.");
        setShowInput(true);
        // setIsLoading(true);
        await handleLeadNotification(response.data, "start");
        // setIsLoading(false);
        setAgentName(response.data.SalesRepresentative_full_name);
        setResponses([]);
        setMessages([]);
        setResponsesNew([]);
      }
    } catch (error) {
      toast.error("Lead creation failed. Please try again!"); // Show error toast
      // Handle error appropriately
      handleClose();
    }
  };

  //End Validate
  const handleInputChangeValue = (event) => {
    setDisplayInputValue(event.target.value);
    setMessageInput(event.target.value);
  };

  const handleCloseModal = () => setShowModal2(false);
  const handleShowModal = () => setShowModal2(true);

  return (
    <div>
      <div className="vera-outer">
        {isLoading ? (
          <div
            className="loader-div"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <div
              className="spinner-border text-primary"
              style={{ width: "4rem", height: "4rem", borderWidth: "0.4rem" }}
              role="status"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : checkUUID === true ? (
          <div className="msge d-flex m-0">
            <div className="leftside-chatbox d-none d-lg-block">
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="header chatbot-header">
                  <div className="msg-text-group d-flex gap-2 align-items-center">
                    <div className="chatbot-img">
                      <img
                        src={chatbotLogo}
                        alt="chatbot-img"
                        className="rounded-circle"
                      />
                    </div>

                    <div>
                      <div className="msg-text1">VERA, The SolarBot</div>

                      {agentName ? (
                        ""
                      ) : (
                        <div className="msg-text2 online-status">
                          <svg
                            width="7"
                            height="7"
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: "5px" }}
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="#C62828" />
                          </svg>
                          Offline
                        </div>
                      )}
                      {agentName && (
                        <div className="msg-text2 online-status" style={{ color: "#0CCDC2" }}>
                          <svg
                            width="7"
                            height="7"
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: "5px" }}
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="#0CCDC2" />
                          </svg>
                          Online 
                        </div>
                      )}
                    </div>
                   
                  </div>
                  
                </div>

                <h6 className="fw-normal">
                  Hi, I’m Vera, your SolarBot that can answer all of your
                  questions surrounding solar for your home.
                </h6>

                <img
                  src={chatbotLeftImage}
                  alt="chatbotLeftImage"
                  className="img-fluid"
                />
              </div>
            </div>

            <div className="msger-chat px-0">
              <div className="header chatbot-header">
                <div className="group align-items-center">
                  <div className="msg-text-group d-flex gap-2 gap-sm-3 align-items-center">
                    <div className="chatbot-img">
                      <img
                        src={chatbotLogo}
                        alt="chatbot-img"
                        className="rounded-circle"
                      />
                    </div>

                    <div>
                      <div className="msg-text1">VERA, The SolarBot</div>
                      {agentName ? (
                        ""
                      ) : (
                        <div
                          className="msg-text2 online-status"
                          style={{ color: "#C62828" }}
                        >
                          <svg
                            width="7"
                            height="7"
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: "5px" }}
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="#C62828" />
                          </svg>
                          Offline
                        </div>
                      )}
                      {agentName && (
                        <div className="msg-text2 online-status">
                          <svg
                            width="7"
                            height="7"
                            viewBox="0 0 7 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{ marginRight: "5px" }}
                          >
                            <circle cx="3.5" cy="3.5" r="3.5" fill="#0CCDC2" />
                          </svg>
                          Online {`(${agentName})`}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="msg-text3">{currentDateTime}</div>
                </div>
              </div>
              <div
                className="chatmsg-container"
                id="scrollable-container"
                ref={scrollRef}
              >
                <div className="msg left-msg align-items-start">
                  <div
                    className="msg-img"
                    style={{ backgroundImage: `url(${chatbotLogo})` }}
                  ></div>

                  <div className="msg-bubble customize-bubble">
                    <div className="msg-text">
                      Hi, I’m Vera, your SolarBot that can answer all of your
                      questions surrounding solar for your home. I am a 3rd
                      party, reliable source without any ties to a specific
                      solar company. I am here to get you the most accurate,
                      fact checked information about solar and help you
                      determine if it makes sense for you! How can I help you
                      today?
                    </div>
                  </div>
                </div>
                {notificationConfirmation && (
                  <button
                    className="msg-bubble1 border-0 mt-2 customize-bubble-btn"
                    onClick={handleShowModal}
                  >
                    <div className="msg-texta">How does solar work?</div>
                  </button>
                )}
                {!notificationConfirmation && staticQuestion === "" && (
                  <button
                    className="msg-bubble1 border-0 mt-2 customize-bubble-btn"
                    onClick={() => handleClick("how-solar-works")}
                  >
                    <div className="msg-texta">How does solar work?</div>
                  </button>
                )}
                {notificationConfirmation && (
                  <div
                    className="msg-bubble1a customize-bubble-btn"
                    onClick={handleShowModal}
                  >
                    <div className="msg-texta">
                      Will Solar save me money on my electric bill?
                    </div>
                  </div>
                )}
                {!notificationConfirmation && staticQuestion === "" && (
                  <div
                    className="msg-bubble1a customize-bubble-btn"
                    onClick={() => handleClick1("save-money")}
                  >
                    <div className="msg-texta">
                      Will Solar save me money on my electric bill?
                    </div>
                  </div>
                )}
                {notificationConfirmation && (
                  <div
                    className="msg-bubble1 customize-bubble-btn"
                    onClick={handleShowModal}
                  >
                    <div className="msg-texta">Is solar really free?</div>
                  </div>
                )}
                {!notificationConfirmation && staticQuestion === "" && (
                  <div
                    className="msg-bubble1 customize-bubble-btn"
                    onClick={() => handleClick2("solar-is-free")}
                  >
                    <div className="msg-texta">Is solar really free?</div>
                  </div>
                )}
                {notificationConfirmation && (
                  <div
                    className="msg-bubble1 customize-bubble-btn"
                    onClick={handleShowModal}
                  >
                    <div className="msg-texta">Other Question ?</div>
                  </div>
                )}
                {!notificationConfirmation && (
                  <div
                    className="msg-bubble1 customize-bubble-btn"
                    onClick={toggleInputVisibility}
                  >
                    <div className="msg-texta">Other Question ?</div>
                  </div>
                )}

                <div>
                  <button
                    className="msg-bubble1 border-0 customize-bubble-btn"
                    onClick={
                      notificationConfirmation ? handleShowModal : handleShow
                    }
                  >
                    <div className="msg-texta">Talk to agent</div>
                  </button>

                  <FormModal
                    show={showModal}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    email={email}
                    setEmail={setEmail}
                    phone={phone}
                    setPhone={setPhone}
                    address={address}
                    setAddress={setAddress}
                    error={error}
                  />
                  <NotificationModal
                    handleClose={handleCloseModal}
                    handleOpen={handleShowModal}
                    modelShow={showModal2}
                  />
                  {/* showInput */}

                  {showInput && notificationConfirmation && (
                    <form
                      className="msger-inputarea"
                      onSubmit={handleClickSendMessage}
                    >
                      <div className="msgchat-bar">
                        <div className="typing-bar agent-chat-box">
                          <input
                            type="text"
                            className="msger-input"
                            value={displayInputValue}
                            pattern="^[^\s].*"
                            onChange={handleInputChangeValue}
                            placeholder="Message..."
                            required
                          />
                          <button
                            type="button"
                            title="Close conversation"
                            className="close-chat"
                            onClick={() => {
                              closeSocket();
                              setShowInput(false); // Hide the input form
                            }}
                          >
                            <img src={deleteIcon} alt="delete" />
                          </button>
                        </div>
                        <button type="submit" className="msger-send-btn">
                          <div>
                            <svg
                              width="26"
                              height="23"
                              viewBox="0 0 26 23"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                                stroke="white"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </button>
                      </div>
                    </form>
                  )}
                </div>

                <div className="chat-messages">
                  {messages?.map((msg, index) => (
                    <div key={index}>
                      <div className="msg right-msg client-response">
                        <div className="msg-bubble-right chatresponse-right">
                          <div className="msg-text">{msg.user}</div>
                        </div>
                      </div>
                      <div className="msg-bubble custom-left">
                        <div className="msg-bubble-left">
                          <div className="msg-texta">{msg.bot}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {notificationConfirmation && (
                  <div className="chat-messages">
                    {chatMessage
                      .filter((data) => {
                        return data !== "";
                      })
                      .map((msg, index) => (
                        <>
                          <div
                            key={index}
                            className="msg-container-main-wrapper"
                          >
                            <div className="msg right-msg">
                              {msg?.user1 && (
                                <div
                                  className="msg-img"
                                  style={{
                                    backgroundImage: `url(${userIcon})`,
                                  }}
                                ></div>
                              )}
                              {msg?.user1 && (
                                <div className="msg-bubble-right chatresponse-right">
                                  <div className="msg-text">{msg.user1}</div>
                                  <span style={{ fontSize: "10px" }}>
                                    {formatTimestampToAMPM(msg.time1)}
                                  </span>
                                </div>
                              )}
                            </div>
                            {msg.user2 && (
                              <div className="msg">
                                {" "}
                                {/* Added a parent div */}
                                <div
                                  className="msg-img"
                                  style={{
                                    backgroundImage: `url(${agentIcon})`,
                                  }}
                                ></div>
                                <div className="msg-bubble">
                                  <div className="msg-bubble-left">
                                    <div className="msg-texta">{msg.user2}</div>
                                    <span style={{ fontSize: "10px" }}>
                                      {formatTimestampToAMPM(msg.time2)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ))}
                  </div>
                )}

                {isVisible && responses?.length > 0 && (
                  <div className="msg-container">
                    {/* First response on the right side */}
                    <div className="msg right-msg ">
                      <div className="msg-bubble-right chatresponse-right">
                        {responses?.length > 0 && (
                          <div className="msg-text">
                            <p>{responses?.[0]}</p>{" "}
                            {/* Display the first response */}
                          </div>
                        )}
                        {responses?.some(
                          (resp) =>
                            resp?.includes("yes") || resp?.includes("no")
                        ) && (
                          <div className="button-container">
                            <button
                              className="response-button"
                              onClick={handleYesClick}
                              disabled={btnDisable?.yes}
                            >
                              Yes
                            </button>
                            <button
                              className="response-button"
                              disabled={btnDisable.yes}
                              // onClick={handleShow}
                              onClick={toggleInputVisibility}
                            >
                              No
                            </button>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Render the second response on the left side */}
                    {responses?.length > 1 && (
                      <div className="msg-bubble custom-left">
                        <div className="msg-bubble-left">
                          <div className="msg-text">
                            <p>{responses?.[1]}</p>{" "}
                            {/* Display the second response */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {/* Render the third response on the right side */}
                {responses?.length > 2 && (
                  <div className="msg-container">
                    <div className="msg right-msg">
                      <div className="msg-bubble-right chatresponse-right">
                        <div className="msg-text">
                          <p>{responses?.[2]}</p>{" "}
                          {/* Display the third response */}
                        </div>
                        {responses?.some(
                          (resp) =>
                            resp?.includes("yes") || resp?.includes("no")
                        ) && (
                          <div className="button-container">
                            <button
                              className="response-button"
                              onClick={handleYesHometownClick}
                              disabled={
                                (responses?.length > 3 || responsesNew.length) >
                                0
                                  ? "disable"
                                  : ""
                              }
                            >
                              Yes
                            </button>
                            <button
                              className="response-button"
                              onClick={handleNoHometownClick}
                              disabled={
                                (responses?.length > 3 || responsesNew.length) >
                                0
                                  ? "disable"
                                  : ""
                              }
                            >
                              No
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {responsesNew?.length >= 1 && (
                  <div className="msg-container">
                    <div className="msg right-msg">
                      <div className="msg-bubble-right chatresponse-right">
                        <div className="msg-text">
                          <p>{responsesNew?.[0]}</p>{" "}
                          {/* Display the forth response */}
                        </div>
                        {responsesNew?.some(
                          (resp) =>
                            resp?.includes("yes") || resp?.includes("no")
                        ) && (
                          <div className="button-container">
                            <button
                              className="response-button"
                              onClick={handleSpouseYesClick}
                              disabled={
                                responsesNew?.length > 1 ||
                                responses?.length > 3
                                  ? "disable"
                                  : ""
                              }
                            >
                              Yes
                            </button>
                            <button
                              className="response-button"
                              onClick={handleSpouseNoClick}
                              disabled={
                                responsesNew?.length > 1 ||
                                responses?.length > 3
                                  ? "disable"
                                  : ""
                              }
                            >
                              No
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the fifth response on the left side */}
                {responsesNew?.length > 1 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responsesNew?.[1]}</p>
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the fourth response on the left side */}
                {responses?.length > 3 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responses?.[3]}</p>{" "}
                        {/* Display the forth response */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the fifth response on the left side */}
                {responses?.length > 4 && (
                  <div className="msg right-msg">
                    <div className="msg-bubble-right chatresponse-right">
                      <div className="msg-text">
                        <p>{responses?.[4]}</p>{" "}
                        {/* Display the fifth response */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the sixth response on the right side */}
                {responses.length > 5 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responses?.[5]}</p>{" "}
                        {/* Display the sixth response */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the sixth response on the right side */}
                {responses.length > 6 && (
                  <div className="msg right-msg">
                    <div className="msg-bubble-right chatresponse-right">
                      <div className="msg-text">
                        <p>{responses?.[6]}</p>{" "}
                        {/* Display the seventh response */}
                      </div>
                      {responses?.some(
                        (resp) => resp?.includes("yes") || resp?.includes("no")
                      ) && (
                        <div className="button-container">
                          <button
                            className="response-button"
                            onClick={handleYesBillClick}
                            disabled={responses?.length > 7 ? "disable" : ""}
                          >
                            Confirm
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Render the sixth response on the right side */}
                {responses.length > 7 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responses?.[7]}</p>{" "}
                        {/* Display the sixth response */}
                      </div>
                    </div>
                  </div>
                )}

                {/* Render the seventh response on the right side */}
                {responses?.length > 8 && (
                  <div className="msg right-msg">
                    <div className="msg-bubble-right chatresponse-right">
                      <div className="msg-text">
                        <p>{responses?.[8]}</p>{" "}
                        {/* Display the seventh response */}
                      </div>
                      {responses?.some(
                        (resp) => resp?.includes("yes") || resp?.includes("no")
                      ) && (
                        <div className="button-container">
                          <button
                            className="response-button"
                            onClick={handleLastYesClick}
                            disabled={responses?.length > 9 ? "disable" : ""}
                          >
                            Yes
                          </button>
                          <button
                            className="response-button"
                            onClick={handleNoClickForBill}
                            disabled={responses?.length > 9 ? "disable" : ""}
                          >
                            No
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {/* Render the tenth response on the right side */}
                {responses?.length > 9 && (
                  <>
                    <div className="static-question-after-select-no">
                      <button
                        className="msg-bubble1 border-0 mt-2"
                        onClick={() => parposalQuestion(1)}
                      >
                        <div className="msg-texta">
                          Q1: What if i need a new roof before going solar, or
                          while the panels are up there?
                        </div>
                      </button>
                    </div>
                    <div className="static-question-after-select-no">
                      <button
                        className="msg-bubble1 border-0 mt-2"
                        onClick={() => parposalQuestion(2)}
                      >
                        <div className="msg-texta">
                          Q2: What happens if i move while i have solar?
                        </div>
                      </button>
                    </div>
                    <div className="static-question-after-select-no">
                      <button
                        className="msg-bubble1 border-0 mt-2"
                        onClick={() => parposalQuestion(3)}
                      >
                        <div className="msg-texta">
                          Q3: What if something breaks, who is responsible to
                          fix my system?
                        </div>
                      </button>
                    </div>
                  </>
                )}

                {/* Render the tenth response on the right side */}
                {responses?.length > 10 && (
                  <div className="msg right-msg">
                    <div className="msg-bubble-right chatresponse-right">
                      <div className="msg-text">
                        <p>{responses?.[10]}</p>{" "}
                        {/* Display the nine response */}
                      </div>
                      {responses?.some(
                        (resp) => resp.includes("yes") || resp.includes("no")
                      ) && (
                        <div className="button-container">
                          <button
                            className="response-button"
                            onClick={() =>
                              lastQuestionConfirmation("proposal_request")
                            }
                            disabled={responses?.length > 11 ? "disable" : ""}
                          >
                            Proposal Request
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {responses?.length > 11 && (
                  <div className="msg-bubble custom-left">
                    <div className="msg-bubble-left">
                      <div className="msg-text">
                        <p>{responses?.[11]}</p>
                      </div>
                    </div>
                  </div>
                )}
                {autoScroll()}
              </div>
              {isInputVisible && (
                <form className="msger-inputarea">
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Something..."
                        value={inputValue}
                        onChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        required
                      />
                    </div>
                    {isOtherQuesLoading ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm" // Change to 'lg' for larger size
                        role="status"
                        aria-hidden="true"
                        // variant="dark"
                        style={{
                          marginLeft: "10px",
                          width: "24px",
                          height: "24px",
                          color: "#189ad3",
                        }}
                      />
                    ) : (
                      <button
                        type="submit"
                        className="msger-send-btn"
                        onClick={handleKeyDown}
                      >
                        <div>
                          <svg
                            width="26"
                            height="23"
                            viewBox="0 0 26 23"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                              stroke="white"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </button>
                    )}
                    {/* <button
                      type="submit"
                      className="msger-send-btn"
                      onClick={handleKeyDown}
                    >
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button> */}
                  </div>
                </form>
              )}

              {/* input for asking for address */}
              {responses?.length > 1 && responses?.length < 3 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Address..."
                        value={inputValue}
                        onChange={handleInputChange}
                        minLength={3}
                        maxLength={50}
                        pattern="^[^\s].*"
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* input for asking for name */}
              {responses?.length > 3 && responses?.length < 5 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit2}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Full Name..."
                        title="Enter First Name & Last Name"
                        value={inputNameValue}
                        onChange={handleNameInputChange}
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* input for asking for email address */}
              {responses?.length > 4 && responses?.length < 6 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit3}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="email"
                        className="msger-input"
                        placeholder="Enter Email..."
                        value={inputEmailValue}
                        onChange={handleEmailInputChange}
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* input for asking for phone number */}
              {responses?.length > 5 && responses?.length < 7 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit4}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Phone Number..."
                        value={inputPhoneValue}
                        onChange={handlePhoneInputChange}
                        pattern="^[0-9]*$"
                        minLength={6}
                        maxLength={10}
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}

              {/* input for asking for Electricity Bill */}
              {responses?.length > 7 && responses?.length < 9 && (
                <form className="msger-inputarea" onSubmit={handleFormSubmit5}>
                  <div className="msgchat-bar">
                    <div className="typing-bar">
                      <input
                        type="text"
                        className="msger-input"
                        placeholder="Enter Electricity Bill..."
                        value={inputBillValue}
                        onChange={handleBillInputChange}
                        pattern="^[0-9]*$"
                        title="Required only numeric value"
                        minLength={1}
                        maxLength={8}
                        required
                      />
                    </div>
                    <button type="submit" className="msger-send-btn">
                      <div>
                        <svg
                          width="26"
                          height="23"
                          viewBox="0 0 26 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.3692 11.5035H3.68428M3.43811 12.5899L2.00324 16.68C1.21738 18.9201 0.824445 20.0402 1.10643 20.7299C1.3513 21.3289 1.87724 21.783 2.52623 21.9559C3.27355 22.1549 4.40229 21.6702 6.65978 20.7008L21.1324 14.486C23.3359 13.5397 24.4376 13.0667 24.7781 12.4094C25.074 11.8384 25.074 11.1684 24.7781 10.5974C24.4376 9.94025 23.3359 9.4671 21.1324 8.52087L6.63482 2.29537C4.38413 1.32889 3.2588 0.84564 2.51222 1.04388C1.86385 1.21604 1.33796 1.66895 1.09224 2.26683C0.809279 2.95528 1.19801 4.07292 1.9755 6.30819L3.44089 10.5213C3.57442 10.9052 3.64119 11.0972 3.66754 11.2935C3.69093 11.4678 3.69069 11.6441 3.66684 11.8183C3.63995 12.0145 3.57267 12.2062 3.43811 12.5899Z"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <h1>Your application has been rejected.</h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;
